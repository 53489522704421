import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token = route.queryParamMap.get('token');
    if (token || token != null) {
      this.router.navigate([`/${token}`]);
      return true; // Allow route
    } 

    else {
      this.router.navigate(['/notFound']); // Redirect if no token
    return false;
    }
  }

}
