import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { InitialiseService } from 'src/app/services/initialise.service';
import { ShareRideService } from 'src/app/share-ride.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cancel-ride',
  templateUrl: './cancel-ride.component.html',
  styleUrls: ['./cancel-ride.component.css']
})
export class CancelRideComponent implements OnInit, OnChanges {

  @Input() cancelRideDto;

  otpToStartRide = "";
  otp={0: null, 1: null, 2: null, 3: null , 4: null, 5: null};
  displayOtpDialog = false;
  cancelRideDialog = false;
  otpTimerToDisplay;
  toggleCancelCta = true;
  isResendDisable = true;
  isVerifyOtpDisabled = true;
  isResendHide = false;
  platform = 'WEB';
  resendTitle = 'Didn’t Received The OTP Yet ?';
  resendCount = 0;


  @ViewChild('ngxotp')
  ngxOtp!: NgxOtpInputComponent;
  otpValue:any;
  otpEntered: boolean = false;
  resendOtpEnabled = false;
  attempts = 0;
  showFailedOTPMessage = false;
  input1: number | undefined ;
  input2: number | undefined ;
  input3: number | undefined ;
  input4: number | undefined ;
  input5: number | undefined ;
  input6: number | undefined ;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    numericInputMode: true,
    
  };

  
  isIncorrectOtp = false;
  isOtpSent = false
  isOtpSentAtStart = false;
  callingCode = "";
  riderPhone = "";

  constructor(private _shareRide: ShareRideService, private _loader: NgxSpinnerService, private _router: Router, private _initialise: InitialiseService, private _cookie: CookieService) { }
  
  ngOnChanges(changes: SimpleChanges): void {
  }
 


  ngOnInit(): void {
    this._cookie.set('rideRequestId', this.cancelRideDto['rideRequestId'])
    this.platform = this._initialise.getPlatform();
  }

  


  getOtp(type){
    let sendOtpDto = {
      phone: this.cancelRideDto['phone'],
      callingCode : this.cancelRideDto['callingCode'] || +91,
      otpSentCount : this.resendCount,
      rideRequestId : this.cancelRideDto['rideRequestId']
    }
    
    this.callingCode = this.cancelRideDto['callingCode']
    this.riderPhone = this.cancelRideDto['phone']

    this.resetOTPModal();
    this.clearOtp();
  
    if(this.platform != 'WEB'){
      if(type == 'send'){
        this.resendCount = 0;  
        this.displayOtpDialog = true;
        this.cancelRideDialog =false;
      }
      this._shareRide.getOTP(sendOtpDto).subscribe(res=>{
      })
    this.toggleTimer();
    }
    
    else{
    this.displayOtpDialog = false;
    this.cancelRideDialog = true;
  }
  }
  
  
  resendOtp(){
    this.isOtpSent = true;
    setTimeout(()=>{
      this.isOtpSent = false;
    },3000)
    ++this.resendCount;
    this.getOtp('resend')
    this.resendCount = this.resendCount > 2 ? 0 : this.resendCount;
    this.toggleTimer()
  }
  
  
  verifyOtp(){
    let verifyOtpDto = {
      otp : this.otpValue.toString(),
      rideRequestId: this.cancelRideDto['rideRequestId']
    }
  this._loader.show()
    this._shareRide.verifyOTP(verifyOtpDto).subscribe(res=>{
      this._loader.hide()
      if(res['statusCode'] >= 300){
        if(res['message'] == 'Sorry! Your ride is already started and cannot be cancelled at this moment.'){
          this.displayOtpDialog = false;
          Swal.fire({
            icon: 'error',
            title: 'Ride Started !',
            text: res['message']
          })
        }  
        this.isIncorrectOtp = true; 
      }
        else{
          this.isIncorrectOtp = false;
          this._router.navigate(['/reasons'])
          
        }
        
      }
    )
  }
  
  
  toggleTimer(){
    let otpTimer = 20;
    this.otpTimerToDisplay = `${otpTimer}`
    this.isResendDisable = true;
    let timer = setInterval(()=>{
      if(otpTimer == 1){
        --otpTimer;
        this.otpTimerToDisplay = otpTimer < 10 ? `0${otpTimer}` : `${otpTimer}`
        this.isResendDisable = false;
        if(this.resendCount == 2){
          this.isResendHide = true;
          this.resendTitle = "Received OTP via call?"
        } 
        else{
          this.isResendHide = false;
        } 
        clearInterval(timer)
      }
      else{
        --otpTimer;
        this.otpTimerToDisplay = otpTimer < 10 ? `0${otpTimer}` : `${otpTimer}`
        return otpTimer
      }
    }, 1000)
  }
  
  
  resetOTPModal(){
    this.input1 = undefined;
    this.input2 = undefined;
    this.input3 = undefined;
    this.input4 = undefined;
    this.input5 = undefined;
    this.input6 = undefined;
    this.otpEntered = false;
    this.attempts =0
    this.resendOtpEnabled = false;
    this.showFailedOTPMessage = false;
  
  }
  
  
  
  handeOtpChange(value: any): void {
    this.isIncorrectOtp = false;
    for (const iterator of value) {
      if(iterator =='')
        this.otpEntered = false;  
        this.isVerifyOtpDisabled = true;
  
    }
  }
  
  handleFillEvent(value: any): void {
    this.otpEntered =true;
    this.otpValue =value;
    this.isVerifyOtpDisabled = false;
  }
  
  clearOtp(){
    this.ngxOtp.clear();
  }
  
  cancelRideAction(){
      let cancelRideDto ={
        action: 'CANCEL',
        rideRequestId: this.cancelRideDto['rideRequestId']
      }
      this._loader.show()
      this._shareRide.cancelRide(this.cancelRideDto['rideRequestId'], cancelRideDto, this._initialise.getUrlToken()).subscribe(res=>{
        this._loader.hide()
        if(res['statusCode'] == 200){
          this._router.navigate(['/reasons'])
        }
  
        else{
          this.cancelRideDialog = false;
          if(res['message'] == 'Sorry! Your ride is already started and cannot be cancelled at this moment.'){
            Swal.fire({
              icon: 'error',
              title: 'Ride Started !',
              text: res['message']
            })
          }
  
          else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Technical Error'
          })
        }
        }
      })
    }
  
    closeDialog(type){
      if(type == 'otpScreen'){
        this.displayOtpDialog = false;
      }
      else{
      this.cancelRideDialog = false;
      }
    }

}
