<div class="mainContainer" id="mainContainer">
    <div id="cancelImg">
        <img src="../../assets/reasons.svg" alt="">
    </div>

    <span class="imgTitle">Your Ride has been cancelled</span>

    <div id="reasonsContainer">
        <span class="title">Please tell us why? </span>
        <span class="sub-title">We highly value your feedback</span><br>

        <div id="reasons"> 
            <div *ngFor="let item of reasons" class="reason">
                <label class="reason">
                <input type="radio" name="selectedReason" [value]="item" (click)="checkReason($event)">
                 <span>{{item}}</span>
                </label>
            </div>
            <textarea name="" id="" cols="30" rows="10" [(ngModel)]="reasonText" *ngIf="selectedReason == 'Other'" class="customReason"></textarea>
            <!-- <input type="text" class="customReason" > -->
        </div>
    </div>

    <div class="ctaContainer">
        <button class="submitCta" (click)="submitReason()" id="submitReasonCta" [disabled]="selectedReason == undefined">Submit Reason</button>
    </div>

</div>

<p-dialog [(visible)]="successDialog" [modal]="true" [style]="{width: 'max-content'}" [draggable]="false" [resizable]="false">
   <div class="successContainer">
    <img src="../../assets/success.svg" alt="">
    <p>Thanks for your feedback</p>
   </div>
  </p-dialog>