<div class="main-container">
    <div class="stops-container">
        <div class="stops-title">
            <span>Ride Route</span>
            <img src="../../../assets/expand-arrow.svg" alt="" (click)="toggleStopsCard()" [ngClass]="{'stop-arrow-expand': !toggleCard, 'stop-arrow-shrink': toggleCard}">
        </div>

        <div class="stops-card">
            <div *ngFor="let stop of tempStopsList; let i = index" class="stops">

                <div class="stops-data-container" [ngClass]="{'stop-reached': stop?.['status'] == 'REACHED', 'stop-not-reached': stop?.['status'] != 'REACHED'}">
                    <div class="location-container">
                        <div class="marker-container">
                            <img [src]="locationIcons[stop?.type]?.['imgUrl']"  *ngIf="stop?.type != 'STOP'">
                            <span class="marker stops-index" *ngIf="stop?.type == 'STOP'">{{i}}</span>
                            <div [ngClass]="{'track-line-reached': stop?.['status'] == 'REACHED', 'track-line-not-reached': stop?.['status'] != 'REACHED'}" *ngIf="stop?.type != 'DROP'"></div>
                            <span class="estimated-distance" *ngIf="stop?.type != 'DROP'">{{stop?.['distanceToNextStop'] | estimatedDistance}} Kms</span>
                        </div>

                        <div class="location">
                            <span>{{stop?.['place']}}</span>
                            <span  *ngIf="stop?.type != 'STOP'">
                                <strong>({{locationIcons[stop?.type]?.etaTitle}} : {{locationIcons[stop?.type]?.['timestamp'] | timeConversion}})</strong>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>