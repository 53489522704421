import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timediff'
})
export class TimeDifference implements PipeTransform {
  transform(minorTime = Date.now() ) {
    if(!minorTime){
      minorTime = Date.now();
    }
    var  majorTime = Date.now();
    let diff = ( Math.floor((majorTime - minorTime) / 1000) ); //seconds

    var time = ' ago';
    if(diff<0){
      time = '';
    }
    diff = Math.abs(diff);
    if (diff < 60) {
      return  diff + ' sec' + time;
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min' + time;
    }
    else {

      let minute = Math.floor(diff/60);
      //console.log('minute',minute);

      let hour = Math.floor(minute/60);
      //console.log('hours',hour);

      minute = Math.floor(minute - hour*60);
      //console.log('minute',minute);
      return  hour + ' hr ' + minute + ' min' + time;
    }
  } 
}

@Pipe({
  name: 'estimatedDistance'
})
export class estimatedDistance implements PipeTransform{
  transform(val){
    if(val){
      return +val.toFixed(2);
    }
    else {
      return val
    }
    
  }
}

@Pipe({
  name: 'timeConversion'
})
export class TimeConversion implements PipeTransform{
  transform(value: any, ...args: any[]) {

    if(value != null){
      let selectedDateTime = new Date(value);
     
      let hour = selectedDateTime.getHours();
      let hoursToShow = hour >= 12 ? (12 - (24%selectedDateTime.getHours())) : selectedDateTime.getHours();
      let timeSpan = hour >= 12 ? 'PM' : 'AM';
      let minutes = selectedDateTime.getMinutes()
  
      return `${hoursToShow} : ${minutes} ${timeSpan}`
    }
    
    else{
      return value;
    }
  }
  
}