import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { InitialiseService } from 'src/app/services/initialise.service';
import { ShareRideService } from 'src/app/share-ride.service';

@Component({
  selector: 'app-scheduled-rentals',
  templateUrl: './scheduled-rentals.component.html',
  styleUrls: ['./scheduled-rentals.component.css']
})
export class ScheduledRentalsComponent implements OnInit, OnChanges {

  @Input() rideData = {}
  rideStateDto = [];
  otpDto = {};
  mapDto = {};
  driverDetailsDto = {};
  rentalPackageDto = {};
  cancelRideDto = {};
  stopsListDto = [];
  stopsEtas = {eta : null, etd: null}
  timeInterval;
  user1Name = "";

  isTripStarted = false;
  isMobileView = false;

  constructor(private _initialise: InitialiseService, private _shareRide: ShareRideService) { 
  }

  @HostListener('window:resize', ['$event'])
  checkScreenSize(){
    const screenWidth = window.innerWidth;
    this.isMobileView = screenWidth < 768 ? true : false;
    this.mapDto['mapZoom'] = screenWidth < 768 ? 8 : 12;
}

  ngOnChanges(changes: SimpleChanges): void {
    this.isTripStarted = this._initialise.checkIfRideIsStarted(this.rideData['rideState']);
    this.rideStateDto = this._initialise.getRideStatesDto(); // all static data in service
    this.setData(this.rideData) // data from api
    this.checkScreenSize();
  }


  ngOnInit(): void {
    this._initialise.setPlatform();
    this.setCancelRideDto();
    this.checkScreenSize();
  }

  setData(data){
    
    this.setOtpDto(data.rideState);
    this.setdriverDetailsDto();
    this.setRentalPackageDto(data['rideState']);
    this.setStopsListDto();
    this.setMapDto()
    this.getDriverLocationFromServer();
    this.getDriverLocation();
    this.user1Name = `{${data['bookForSomeoneElseDataDto'] ? data['bookForSomeoneElseDataDto']['firstName'] : ''}}`;
  }

  setOtpDto(currentState){
      let otpObj = {};
      this.rideStateDto.forEach((state)=>{
      if(state.state == currentState){
        otpObj['message'] = state['message'];
        otpObj['name'] = this.isTripStarted ? this.rideData['rider']['firstName'] : this.rideData['driver']['firstName'];
        otpObj['otpToStart'] = this.rideData['otpToStart'] ? this.rideData['otpToStart'] : null;
        otpObj['otpToEnd'] = this.rideData['otpToEndRide'] ? this.rideData['otpToEndRide'] : null;
        otpObj['rideState'] = this.rideData['rideState'];

        this.otpDto = otpObj
      }
    })
  }

  setdriverDetailsDto(){
    let driverDto = {}
    driverDto['name']=`${this.rideData['driver']['firstName']} ${this.rideData['driver']['lastName']}`;
    driverDto['phone'] = `${this.rideData['driver']['callingCode']}-${this.rideData['driver']['phoneNumber']}`
    driverDto['vehicleName']=this.rideData['displayName'];
    driverDto['vehicleNumber'] = this.rideData['vehicleNumber']

    this.driverDetailsDto = driverDto;
  }

  setRentalPackageDto(state){
    let packageDto = {}

    packageDto['isPremium'] = this.rideData['otherFlags']['subCategory'] == "PREMIUM_RENTALS" ? true : false;
    packageDto['package'] = this.getPackageDetails(this.rideData['rentalBreakup'] || null)['package'];
    packageDto['baseDistance'] = this.getPackageDetails(this.rideData['rentalBreakup'] || null)['distance'];
    packageDto['hours'] = this.getPackageDetails(this.rideData['rentalBreakup'] || null)['hours'];
    packageDto['totalKms'] = this.getTotalKms(this.rideData['stopsList']);
    packageDto['rideState'] = state;
    packageDto['startTimestamp'] = this.rideData['otherFlags']['estimatedPackageStartTimestamp'] ? this.rideData['otherFlags']['estimatedPackageStartTimestamp'] :  0;
    packageDto['stopTimestamp'] = this.rideData['estimatedCompletionTimestamp'] ? this.rideData['estimatedCompletionTimestamp'] : 0;
    packageDto['pickUpTimestamp'] = this.rideData['pickUpTimestamp'] ? this.rideData['pickUpTimestamp'] : 0
    this.rentalPackageDto = packageDto;
  }

  setCancelRideDto(){
    let cancelDto = {}
    cancelDto['callingCode']=this.rideData['rider']['callingCode'];
    cancelDto['phone'] = this.rideData['rider']['phoneNumber'];
    cancelDto['rideRequestId']=this.rideData['rideRequestId'];

    this.cancelRideDto = cancelDto
  }


  setStopsListDto(){
    let stopsDto = [];
    stopsDto = Object.keys(this.rideData['stopsList']).map(key=>this.rideData['stopsList'][key])
    this.stopsListDto = stopsDto;
  }

  async setMapDto(carLocationLat = null, carLocationLng = null){
    let mapObj = {}
    let stopStatus =await this.stopsListDto.map(stop=>stop?.status);

    if(this.stopsListDto.length>1 && this.isTripStarted){
      if(!stopStatus.includes('LEFT')){
        mapObj['pick'] = {lat: this.stopsListDto[this.stopsListDto.length-2]['lat'] , lng: this.stopsListDto[this.stopsListDto.length-2]['lng']};
        mapObj['drop'] = {lat: this.stopsListDto[this.stopsListDto.length-1]['lat'] , lng: this.stopsListDto[this.stopsListDto.length-1]['lng']}; 
      }
      else if(!stopStatus.includes('REACHED')){
        mapObj['pick'] = {lat: this.stopsListDto[0]['lat'] , lng: this.stopsListDto[0]['lng']};
        mapObj['drop'] = {lat: this.stopsListDto[1]['lat'] , lng: this.stopsListDto[1]['lng']}; 
      }
      else{
        let leftIndex = stopStatus.indexOf('LEFT');
        mapObj['pick'] = {lat: this.rideData['stopsList'][leftIndex - 1]['lat'] , lng: this.rideData['stopsList'][leftIndex - 1]['lng']};
        mapObj['drop'] = {lat: this.rideData['stopsList'][leftIndex]['lat'] , lng: this.rideData['stopsList'][leftIndex]['lng']};    
      }
    }

    else{
      mapObj['pick'] = {lat: this.rideData['stopsList'][0]['lat'] , lng: this.rideData['stopsList'][0]['lng']};
      mapObj['drop'] = {lat: carLocationLat , lng: carLocationLng};    
    }

      mapObj ['stops'] = this.getStopsLatLng([]);
      mapObj['carLocation'] = {lat: carLocationLat, lng: carLocationLng};
      mapObj ['isTripStarted'] = this.isTripStarted;
      this.mapDto = mapObj;
  }

  getStopsLatLng(stopsList){
    let stopsArr = Object.values(stopsList);
    stopsArr.shift();
    stopsArr.pop();

    let stopsLatLng = stopsArr.map((stop, index)=>{
        return {lat: stop['lat'], lng: stop['lng']}
    })

    return stopsLatLng
  }

  getPackageDetails(breakup){
    if(breakup != null){
    let hours = breakup['baseTime']/3600000;
    let distance = breakup['baseDistance']/1000;
    return {hours : hours, distance: distance, package: `${hours}Hr/${distance}Kms`}
    }
    else{
      return '3hr / 30Km'
    }
}


getDriverLocation(){
  this.timeInterval = setInterval(()=>{
    this.getDriverLocationFromServer()
  }, 15000)
}

getTotalKms(list){
  let totalKms = 0;
  Object.values(list).forEach(stop=>{
    if(stop['distanceToNextStop'] != null){
      totalKms += stop['distanceToNextStop']
    }
  })
  return totalKms.toFixed(2);
}

getDriverLocationFromServer(){
  this._shareRide.getDriverLocation(this.rideData['driverId'], this.rideData['id']).subscribe(res=>{
    if(res['statusCode'] == 200){
      
      let response = res['response']
      this.setMapDto(response['latitude'], response['longitude'])
      this.stopsEtas = {eta: response.eta, etd: response.etd};
    }
  })
}


}
