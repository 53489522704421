
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ShareRideService } from 'src/app/share-ride.service';
import { mapStyles } from 'src/app/utils/map-utils';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnChanges {
  @Input() mapDto = {}


  carRotation;

  agmCredentials ={
    renderOptions : { suppressMarkers: true, polylineOptions: { strokeColor: '#2C66E3' ,strokeWeight: 3}}, // suppressMarker hide the default markers for pick and drop, polylineoptions set path styling
    pick : { lat: 28.393497, lng: 76.96323 }, // set lat lng for pickup location
    destination : { lat: 28.507914, lng: 77.08584 }, // set lat lng for destination location
    stops: [{location: {lat: 28.477028349937264,  lng: 77.07059920236028}, stopover: false}],
    markerOptions : {"origin": this.getMarkerOptionsDto('origin'),
                     "destination": this.getMarkerOptionsDto('destination'),
                     "waypoints": this.getMarkerOptionsDto('waypoints')        
                    },
    carLocation: { lat: 28.507914, lng: 77.08584 },
    options : mapStyles
    } 

  

  
  constructor(private _shareRide: ShareRideService, private _mapsApiLoader: MapsAPILoader) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.setDtoData(this.mapDto);
    this.getCarRotation().then(res=>{
      this.carRotation = res;
    });
  }

  setDtoData(data){
    this.agmCredentials['pick'] = data['pick'];
    this.agmCredentials['destination'] = data['drop'];
    this.agmCredentials['stops'] = this.getStopsLatLngForMap(data['stops'])

    this.agmCredentials['markerOptions'] = 
      {"origin": this.getMarkerOptionsDto('origin'),
        "destination": this.getMarkerOptionsDto('destination'),
        "waypoints": this.getMarkerOptionsDto('waypoints')        
       }
  }

  getStopsLatLngForMap(stopsLatLngList){
    let newStopsLatLngList = stopsLatLngList.map(stop=>{
      return {location: stop, stopover: false}
    })
    return newStopsLatLngList;
  }



 async getCarRotation(){
    let locationPolyLine = await this.getPolyLinePath(this.mapDto['pick'], this.mapDto['drop']);
  
    let carLocationPoint1 = this.mapDto['carLocation'];
    let carLocationNextPoint = await this.getNextPoint(carLocationPoint1, locationPolyLine);
    console.log(carLocationNextPoint);

    this.agmCredentials['carLocation'] = {
      lat : carLocationNextPoint['lat'],
      lng: carLocationNextPoint['lng']
    }
    // this.agmCredentials['carLocation'] = Object.create({}, carLocationNextPoint);

    if(carLocationNextPoint){
      const deltaX = carLocationNextPoint['lng'] - carLocationPoint1['lng'];
      const deltaY = carLocationNextPoint['lat'] - carLocationPoint1['lat'];
      
      let angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
      angle = (angle + 360) % 360;
      angle = 360 - angle;
      console.log(angle)
      return angle;
    }

    else{
      return null;
    }
    
  }

  async getPolyLinePath(origin, destination){

    let x  = new Promise((resolve, reject)=>{
      let polylinePath = [];
      this._mapsApiLoader.load().then(async res=>{
        await new google.maps.DirectionsService().route({origin: origin, destination: destination, travelMode: google.maps.TravelMode.DRIVING}, (res,status)=>{
          if (status === google.maps.DirectionsStatus.OK) {
            new google.maps.DirectionsRenderer().setDirections(res);
    
            // Extract the polyline path from the response
            const route = res.routes[0];
            const legs = route.legs[0];
            const steps = legs.steps;
    
            steps.forEach(step => {
              const nextSegment = step.path;
              nextSegment.forEach(point => {
                polylinePath.push({ lat: point.lat(), lng: point.lng()});
              });
            });
        }});

        resolve(polylinePath);
      })
    })
    return x;
  }


  getNextPoint(currentPoint, polyline){
    console.log(currentPoint, polyline, 'hi bhai')

  let x  = new Promise((resolve, reject)=>{
    let closestPoint = null;
    let minDistance = Infinity;
    // Iterate over polyline points to find the closest point after currentPoint
    for (let i = 0; i < polyline.length; i++) {
      const point = polyline[i];
  
      // Skip the current point
      if (point.lat === currentPoint.lat && point.lng === currentPoint.lng) {
        continue;
      }
  
      // Calculate the distance between the currentPoint and this point
      const distance = this.calculateDistance(currentPoint, point);
  
      // Update closestPoint if this point is closer
      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = point;
      }
    }
    resolve(closestPoint);
  });

    return x;
  }

  calculateDistance(point1, point2){ 
    const R = 6371; // Radius of the Earth in km
    const dLat = this.degreesToRadians(point2.lat - point1.lat);
    const dLng = this.degreesToRadians(point2.lng - point1.lng);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(point1.lat)) * Math.cos(this.degreesToRadians(point2.lat)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }
  
  radians_to_degrees(radians){
  let pi = Math.PI;
  return radians * (180/pi);
}



  degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }


  getMarkerOptionsDto(type){
    let urls = {origin : '../../../assets/pick-location-icon.svg',
                destination: '../../../assets/drop-location-icon.svg',
                waypoints: '../../../assets/stops-icon.svg'}

     let width: '10px';
     let height: '10rem';             
  
     return {
      icon: urls[type],
      width: width,
      height : height,
      visible: type == 'waypoints' || (type == 'destination' && !this.mapDto['isTripStarted'])? false : true
     }

  }

  


}

